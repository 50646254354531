import React, { useEffect } from "react";
const FacePixel = () => {
  useEffect(() => {
    // Create a new script element for the Facebook Pixel
    const scriptElement = document.createElement("script");

    // Set the script content for the Facebook Pixel
    scriptElement.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '3745595645684204');
        fbq('track', 'PageView');
      `;

    // Append the script to the head section of the document
    document.head.appendChild(scriptElement);

    // Create a noscript element for the Facebook Pixel
    const noscriptElement = document.createElement("noscript");

    // Set the noscript content for the Facebook Pixel
    noscriptElement.innerHTML = `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=3745595645684204&ev=PageView&noscript=1"
        />
      `;

    // Append the noscript element to the head section of the document
    document.head.appendChild(noscriptElement);

    // Cleanup function to remove the script and noscript when the component unmounts
    return () => {
      document.head.removeChild(scriptElement);
      document.head.removeChild(noscriptElement);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return null; // Since this component only adds scripts, it doesn't render anything
};
export default FacePixel;
